import { ClipLoader } from "react-spinners";
import React from "react";

const Loading = props => {
  const style = {
    position: "fixed",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -25%)"
  };
  const style1 = {
    position: "fixed",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -25%)"
  };
  return (<React.Fragment>
    <div style={style}>
      <ClipLoader color={"#01D35A"} loading={true} />
      <br></br>
    </div>
    <div style={style1}>
    <h5>Please do not press any key</h5>
    </div>
    </React.Fragment>
  );
};

export default Loading;
