import React, { Component } from "react";
import axios from "axios";
//import env from './.env';
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_amount: 100,
      refund_id: 0,
      paymentData: {},
      razorpayId: '',
      showPaytm: false
    };
  }

  async componentDidMount() {
    console.log(this.props)
    const headers = {
      "Content-Type": "application/json"
    };

    console.log(this.props.match.params.payment_id);
    let x = await axios.post(
      "https://devapi.plunes.com/paymentControl/",
      {
        payment_id: this.props.match.params.payment_id
      },
      {
        headers: headers
      }
    )
    console.log(x, 'data')
    this.setState({ paymentData: x.data.data, razorpayId: x.data.data.razorpayId });
    const { paymentAmount, bookingId } = this.state.paymentData;
    const self = this;

    const options = {
      key: x.data.data.razorpayId, //process.env.REACT_APP_RAZOR_PAY_TEST_KEY,
      amount: parseInt(paymentAmount) * 100,
      currency: 'INR',
      receipt: bookingId,
      payment_capture: '1',
      name: "Plunes Technologies",
      description: "",
      order_id: x.data.data.order_id,
      redirect: true,
      options: {
        checkout: {
          name: "Plunes Technologies"
        }
      },
      callback_url: "https://devapi.plunes.com/paymentControl/capture/" + this.state.paymentData.bookingId + '/' + x.data.data.order_id,
      prefill: {
        contact: this.state.paymentData.phone_number,
        email: this.state.paymentData.email,
        name: this.state.paymentData.userName
      },
      external: {
        wallets: ['paytm'],
        handler: function (data) {
          console.log(data, x.data.data)
          var params = {
            orderId: x.data.data.order_id,
            email: x.data.data.email,
            contact: x.data.data.phone_number,
            customerId: x.data.data.customerId,
            bookingId: x.data.data.bookingId,
            amount: x.data.data.paymentAmount
          }
          window.location.replace(`https://devapi.plunes.com/paymentControl/paytm?orderId=${params.orderId}&customerId=${params.customerId}&contact=${params.contact}&email=${params.email}&bookingId=${params.bookingId}&amount=${params.amount}`)
        }
      },
      theme: {
        color: "#01D35A"
      },
      modal: {
        ondismiss: () => {
          window.location.replace("https://devapi.plunes.com/paymentControl/cancelled/" + this.props.match.params.payment_id);
        }
      }
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', function (response) {
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata);
      axios.post("https://devapi.plunes.com/paymentControl/error/" + this.state.paymentData.bookingId, response.error)
        .then(console.log("success"))
        .catch(console.log("error"));
    })
  }

  render() {
    console.log(this.state);
    return (this.state.showPaytm ? <div dangerouslySetInnerHTML={{ __html: this.state.webpage }} /> : <div />)
  }
}

export default Checkout;
